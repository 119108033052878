import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import api from '../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import { FaCalendarDays } from "react-icons/fa6";

export interface LaunchData {
  _id: string;
  launchName: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  orderNumber: string; // Yeni alan eklendi
}


interface SeoData {
  title: string;
  socialImage: string;
  description: string;
  url: string;
}

interface SearchComponentSectionProps {
  componentData: 'ongoing' | 'upcoming' | 'past'; // Lansman türü belirleme
}

const LaunchSearchPage: React.FC<SearchComponentSectionProps> = ({ componentData }) => {
  const [launches, setLaunches] = useState<LaunchData[]>([]);
  const [filteredLaunches, setFilteredLaunches] = useState<LaunchData[]>([]);
  const [displayedLaunches, setDisplayedLaunches] = useState<LaunchData[]>([]);
  const [seoDataMap, setSeoDataMap] = useState<{ [key: string]: SeoData }>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState<'TR' | 'EN'>('TR'); // Başlangıç dili

  const navigate = useNavigate();
  const location = useLocation();

  // URL değişikliklerini izleyerek HTML lang özelliğini güncelle
  useEffect(() => {
    const updateLanguageFromPath = () => {
      const path = location.pathname;

      if (path.startsWith('/en')) {
        setLanguage('EN');
        document.documentElement.lang = 'en';
      } else if (path.startsWith('/tr')) {
        setLanguage('TR');
        document.documentElement.lang = 'tr';
      }
    };

    // İlk render sırasında ve URL değişikliklerinde çalıştır
    updateLanguageFromPath();
  }, [location]);

  useEffect(() => {
    const fetchLaunches = async () => {
      try {
        setLoading(true);
        const response = await api.get<LaunchData[]>(`${process.env.REACT_APP_API_URL}/launch`);
        setLaunches(response.data);
        categorizeLaunches(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Lansman verileri alınamadı:', error);
        setLoading(false);
      }
    };
    fetchLaunches();
  }, [componentData]);

  // Verileri kategorize etme
  const categorizeLaunches = (launches: LaunchData[]) => {
    const today = new Date();
    let filteredData: LaunchData[] = [];

    if (componentData === 'ongoing') {
      filteredData = launches.filter(
        (launch) =>
          new Date(launch.startDate) <= today &&
          new Date(launch.endDate) >= today &&
          launch.isActive
      );
    } else if (componentData === 'upcoming') {
      filteredData = launches.filter(
        (launch) => new Date(launch.startDate) > today && launch.isActive
      );
    } else if (componentData === 'past') {
      filteredData = launches.filter(
        (launch) => new Date(launch.endDate) < today && launch.isActive
      );
    }

    // orderNumber'ı sayıya çevir ve startDate'e göre sıralama
    filteredData.sort((a, b) => {
      const orderA = parseInt(a.orderNumber, 10); // String'i sayıya çevirme
      const orderB = parseInt(b.orderNumber, 10); // String'i sayıya çevirme

      if (orderA === orderB) {
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
      }
      return orderA - orderB;
    });

    setFilteredLaunches(filteredData);
    setDisplayedLaunches(filteredData); // İlk yükleme sırasında tüm lansmanları gösteriyoruz
    // SEO verilerini gecikmeli olarak yüklemeye başla
    fetchAllSeoData(filteredData);
  };



  // SEO verilerini gecikmeli olarak getirme
  const fetchAllSeoData = async (launches: LaunchData[]) => {
    const seoMap: { [key: string]: SeoData } = {};
    for (const launch of launches) {
      try {
        const response = await api.get<SeoData>(`${process.env.REACT_APP_API_URL}/seosettings/${launch._id}`);
        seoMap[launch._id] = response.data;
      } catch (error) {
        console.error(`SEO verileri alınamadı: ${launch._id}`, error);
      }
    }
    setSeoDataMap(seoMap); // SEO verilerini yüklüyoruz
  };

  // Arama işlemi (sadece o sayfadaki lansmanlar)
  useEffect(() => {
    const filteredResults = filteredLaunches.filter((launch) =>
      launch.launchName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setDisplayedLaunches(filteredResults); // Arama sonuçlarını displayedLaunches'a set ediyoruz
  }, [searchTerm]);

  const renderLaunchCards = (launches: LaunchData[]) => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
        {launches.map((launch, index) => {
          const launchSeoData = seoDataMap[launch._id];
          const isUpcoming = componentData === 'upcoming';

          return (
            <div
              key={launch._id}
              className={`relative p-4 bg-white rounded-lg shadow-md min-h-[350px] flex flex-col justify-between transform transition-transform duration-500 hover:scale-105 overflow-hidden`}
              style={{ transitionDelay: `${index * 150}ms` }}
            >
              {/* Lansman görseli */}
              <div className="h-48 bg-gray-100 rounded-md flex items-center justify-center overflow-hidden shadow-sm">
                <img
                  src={launchSeoData?.socialImage || 'https://via.placeholder.com/150'}
                  alt="Launch"
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                />
              </div>

              {/* Lansman başlığı ve açıklaması */}
              <div className="flex-grow">
                <h3 className="text-lg font-semibold mt-3 text-gray-900">
                  {launchSeoData?.title || launch.launchName}
                </h3>

                <p className="text-gray-600 mt-1 line-clamp-2 break-words text-sm">
                  {launchSeoData?.description || (language === 'EN' ? 'Launch description will be here.' : 'Lansman açıklaması burada yer alacak.')}
                </p>
              </div>

              {/* Gelecek lansmanlar için Takvim ikonu ve "Daha Fazlası" butonu yan yana sabit */}
              <div className="flex items-center justify-between mt-auto w-full space-x-2 pt-4">
                {isUpcoming && (
                  <div className="flex items-center bg-red-600 text-white px-2 py-1 rounded-full flex-shrink-0">
                    <div className="flex items-center justify-center bg-white text-gray-900 rounded-full w-8 h-8 mr-1 flex-shrink-0">
                      <FaCalendarDays className="h-4 w-4" />
                    </div>
                    <span className="text-xs font-medium whitespace-nowrap">
                      {new Date(launch.startDate).toLocaleDateString()} {language === 'EN' ? 'LIVE' : 'YAYINDA'}
                    </span>
                  </div>
                )}

                {/* "Daha Fazlası" butonu her zaman gösterilecek */}
                <button
                  onClick={() => navigate(`/${launchSeoData?.url || `/launch/${launch._id}`}`)}
                  className="text-blue-500 underline hover:text-blue-700 text-xs ml-auto"
                >
                  {language === 'EN' ? 'Learn more...' : 'Daha fazlası...'}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="p-4 mx-auto min-h-[70vh] max-w-full md:max-w-[85vw]">
      <Navbar /> {/* Navbar'ı en üste ekledik */}
      <div className="mt-36"> {/* Navbar ile çakışmayı önlemek için margin-top ekledik */}
        <div className="relative w-full mb-8">
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-3 border shadow-lg rounded-sm text-gray-700 focus:outline-none focus:ring-2"
            placeholder={language === 'EN' ? 'Launch search' : 'Lansman Ara'}
          />

        </div>

        {loading ? (
          <div>Yükleniyor...</div> // Yüklenme sırasında gösterilecek animasyon
        ) : (
          <div className="mt-4">
            {renderLaunchCards(displayedLaunches)}
          </div>
        )}
      </div>
    </div>
  );
};

export default LaunchSearchPage;
